@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply la-text-rg;
    }

    body {
        @apply la-bg-white la-text-black la-font-body la-overflow-x-hidden la-overflow-y-auto;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply la-font-bold la-font-title la-leading-tight;
    }
        
    h1 {
        @apply la-text-4xl sm:la-text-3xl;
    }

    h2 {
        @apply la-text-3xl sm:la-text-xl;
    }

    h3 {
        @apply la-text-2xl sm:la-text-lg;
    }

    h4 {
        @apply la-text-xl sm:la-text-lg;
    }

    h5 {
        @apply la-text-lg sm:la-text-rg;
    }

    h6 {
        @apply la-text-rg;
    }

    p {
        @apply la-leading-normal la-mb-3;
    }

    label {
        @apply la-inline-block la-mb-1;
    }

    img {
        display: inherit;
    }

    hr {
        @apply la-my-3;
    }

    select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%231D1D1D'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
        background-position: right 20px center;
        background-size: 12px;
    }

    ul {
        @apply la-list-disc la-pl-3;
    }
}